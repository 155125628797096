@import url('https://fonts.googleapis.com/css2? family = Quicksand: wght @ 300; 400; 500; 600; 700 & display = swap');

@import url('https://fonts.googleapis.com/css2? família= Poppins:wght@400;500;700;900 & display=swap');

@font-face {
    src: url(../assets/fonts/Panton-BlackCaps.otf);
    font-family: 'Panton-BlackCaps';
    font-weight: normal;
}

* {
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Poppins', sans-serif;
    line-height: 18px;
    background-color: #F1F1F3;
    font-size: clamp(0.75rem, 0.518rem + 0.4225vw, 2rem);
}

h1, p, input, button {
    font-family: 'Poppins', sans-serif;
}

h1, label {
    font-size: clamp(12px, 14px, 16px);
    font-family: 'Panton-BlackCaps';
    width: max-content;
}

input, select, textarea {
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    text-align: justify;
    border: 1px solid rgba(120,143,156, 0.4);
}

input:focus, select:focus, textarea:focus {
    border: 1px solid #78869C;
}

input::placeholder {
    color: #A1A1A1;
    font-size: 0.8rem;
}

input::selection {
    background: #393E41;
    color: #E2E3E8;
}

input:invalid, select:invalid, textarea:invalid {
    border: #FF6B6C solid 1px;
}

table th, table td {
    font-family: 'Poppins', sans-serif;
    padding: 0 20px;
}

table th {
    position: sticky;
    top: 0;
    background-color: #F1F1F3;
    margin-bottom: 50px;
    height: 50px;
    border-bottom: #FF6B6C solid 1px;
}

table th:nth-child(1) {
    border-radius: 10px 0 0 10px;
}

table tr:last-of-type th:last-of-type  {
    border-radius: 0 10px 10px 0;
}

table tbody {
    overflow: auto;
}

table tbody tr {
    height: 60px;
    cursor: pointer;
    background-color: #F1F1F3;
    transition: all 300ms ease-in-out;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

table tbody tr:hover {
    background-color: #FF6B6C;
    color: #F1F1F3;
}

#buscarMaisDados {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}