.titulo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.titulo {
  font-size: 1.25rem;
  font-weight: bold;
}

.botao-icone {
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.botao-icone:hover {
  background-color: #f3f3f3;
}

.lista-contatos {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}

.cartao-contato {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 8px;
}

.cartao-conteudo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.cartao-conteudo > span {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.nome-contato {
  font-weight: 500;
  color: #4a5568;
}

.email-contato,
.telefone-contato {
  color: #718096;
}

#botoes-acoes {
  display: flex;
  gap: 8px;
  flex-direction: row;
}

#botoes-acoes > span {
  cursor: pointer;
}

.botao-editar:hover,
.botao-excluir:hover {
  background-color: #e2e8f0;
}

.botao-excluir span {
  color: #f56565;
}

.botoes-rodape {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.botao-cancelar {
  width: 50%;
  background-color: #e53e3e;
  color: white;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.botao-cancelar:hover {
  background-color: #c53030;
}

.botao-salvar {
  width: 50%;
  background-color: #38a169;
  color: white;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.botao-salvar:hover {
  background-color: #2f855a;
}

.tagContato {
  cursor: pointer;
}
