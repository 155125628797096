#tituloContasBancarias {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: #FF6B6C solid 1px;
    border-radius: 10px;
}

#contasBancariasContainer {
    overflow-y: auto;
    height: calc(100vh - 90px);
}

#tabelaListaContasBancariasContainer::-webkit-scrollbar, #contasBancariasContainer::-webkit-scrollbar {
    background-color: #FFFFFF;
    border-radius: 0 20px 20px 0;
    width: 5px;
}

#tabelaListaContasBancariasContainer::-webkit-scrollbar-thumb, #contasBancariasContainer::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

#contasBancáriasContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
}

#contasBancáriasContent div {
    flex: 1 0 300px;
    background-color: #F4F5F6;
    height: 310px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-radius: 20px;
}

.tituloCardContasBancarias {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: #FF6B6C solid 1px;
    padding: 10px;
}

#tabelaListaContasBancariasContainer {
    display: block;
    height: 220px;
    margin-top: 5px;
    overflow-y: auto;
}

#tabelaListaContasBancarias, #tabelaExtratoContas {
    width: 100%;
    padding: 10px;
    border-collapse: separate;
    border-spacing: 0 5px;
}

#imgEditarConta {
    width: 30px;
    margin: 0 10px;
}

#imgEditarConta:hover {
    border: #F4F5F6 solid 1px;
    border-radius: 50%;
    box-sizing: border-box;
}

#decricaoContaBancaria {
    padding-left: 30px;
}

#botoesContainerTransferenciaBancaria {
    width: 100%;
    padding: 10px 0;
    align-items: end;
}

#tabelaExtratoContasContainer {
    margin: 0 20px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
}

#dataExtratoContainer {
    display:  flex;
    flex-wrap: wrap;
    align-items: flex-end;
    min-width: 400px;
    gap: 20px;
}

.dataExtrato {
    flex: 1 0 150px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1000px) {
    #contasBancáriasContent div {
        height: max-content;
    }
}
